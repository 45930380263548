import React, { useEffect, useRef } from "react";
import Top from "./components/Top";
import Footer from "./components/Footer";
import Mission from "./components/Mission";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { ThemeProvider } from "./components/ThemeContext";
import Team from "./components/Team";
import Header from "./components/HeaderHome";
import ForInvestors from "./components/ForInvestors";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PhoneMockup from "./components/PhoneMockup";

function App() {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set("language", lang, { sameSite: "None", secure: true });
  };
  const appRef = useRef(null);
  const foundersRef = useRef(null);

  useEffect(() => {
    const savedLanguage = Cookies.get("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const scrollToApp = () => {
    appRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFounders = () => {
    foundersRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Router>
      <ThemeProvider>
        <Routes>
          <Route
            path="/"
            element={
              <div className="flex flex-col w-full relative bg-[#f7f4ed] dark:bg-[#202020]">
                <Header
                  changeLanguage={changeLanguage}
                  scrollToApp={scrollToApp}
                  scrollToFounders={scrollToFounders}
                />

                <Top />
                <div className="hidden md:block">
                  <div
                    className="flex  w-full  flex-col items-center justify-center min-h-screen"
                    ref={appRef}
                  >
                    <h2 className="text-4xl text-black dark:text-[#cfcfcf] font-gnarly-semibold mb-8">
                      {t("app_title")}
                    </h2>
                    <PhoneMockup />
                  </div>
                </div>

                <div className="flex justify-center mb-10">
                  <div
                    className="w-full mx-3 xl:w-1/2 bg-white dark:bg-black rounded-3xl shadow-xl"
                    ref={foundersRef}
                  >
                    <Team />
                  </div>
                </div>

                <div className="w-full mt-20">
                  <Footer />
                </div>
              </div>
            }
          />

          <Route path="/investors" element={<ForInvestors />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
